import Dropdown from "bootstrap/js/src/dropdown";
import Offcanvas from "bootstrap/js/src/offcanvas";
import Popover from "bootstrap/js/src/popover";

import {initHeadroom} from "./js/inits/_headroom.init.js";
import {initNavbarIndicator} from "./js/inits/_navbar.indicator.init.js";
import {initNavbarToggle} from "./js/inits/_navbar.toggle.init.js";
import {initSubNavToggleMobile} from "./js/inits/_navbar.toggle-subnav-mobile.init.js";
import {initNavbarMegaMenu} from "./js/inits/_navbar.mega-menu.init.js";
import {initNavbarHeightVariable} from "./js/inits/_navbar.height-variable.init.js";
import {initPopover} from "./js/inits/_popover.init.js";
import {initCardTitleHeight} from "./js/inits/_card-title-height.init.js";

document.addEventListener('DOMContentLoaded', () => {

  document.documentElement.classList.replace('no-js', 'js-rules');
  (() => {
    initNavbarIndicator();
    initCardTitleHeight()
    initNavbarHeightVariable();
  })();

  (() => {
    initNavbarToggle(Offcanvas);
    initSubNavToggleMobile(Offcanvas);
    initNavbarMegaMenu();
  })();

  (() => {
    initHeadroom();
    initPopover(Popover)
  })()
}, {passive: true});

window.addEventListener('resize', () => {
  initNavbarIndicator();
  initCardTitleHeight();
}, {passive: true});

export default {
  Dropdown: Dropdown
}


